import {AppPageContext} from "#/src/definitions/AppDefinitions";
import {TwoFactorCodeReturn} from "#/src/components/TwoFactorModal";

export function composeTwoFactorHeaders(object: any, code: TwoFactorCodeReturn | null) {
  if(!code) {
    return object;
  }
  object["X-2FA-Code"] = code.code;
  object["X-2FA-Type"] = code.type;
  return object;
}

export function composeHeaders(pageContext: AppPageContext): HeadersInit {
  if(!pageContext.user.loggedIn) {
    return {"X-Source": "portal", "X-Client-Id": pageContext.user.clientId, "Content-Type": "application/json"}
  }
  return {"X-Source": "portal", "X-Client-Id": pageContext.user.clientId, "Authorization": `Bearer ${pageContext.user.accessToken}`, "Content-Type": "application/json"}
}

export function composeQuery(query: any) {
  for (let param in query) {
    if (query[param] === undefined
      || query[param] === null
      || query[param] === ""
    ) {
      delete query[param];
    }
  }
  return new URLSearchParams(query).toString();
}